<template>
    <v-container class="mt-10 my-2 mx-2 px-2 col-md-12 ">
<div class="text-center">
    
    <div class="col-md-6">
        <a href="https://web.facebook.com/sp0mers-team" class="ma-3"> <v-btn color="success"><v-icon>mdi-facebook-messenger</v-icon> 
        {{$t('globals.send')}}
        </v-btn></a>
        <div v-if="!data.length">
      <div class="ma-2" v-for="g in 5" :key="g">
        <v-flex xs12 md10 sm10 justify-center>
          <v-skeleton-loader
            v-bind="attrs"
            type="list-item-avatar-three-line"
          ></v-skeleton-loader>
        </v-flex>
      </div>
    </div>
<v-card v-else class="mt-4 pa-3" v-for="(item,items) in data" :key="items">
    <h3 class="yellow--text"><v-icon>mdi-lead-pencil</v-icon> {{$t('globals.ecr')}} {{items+1}}</h3>
    <v-divider class="ma-3"></v-divider>
    <div v-html="item.text"></div>
</v-card>
    </div>

</div>

    </v-container>
</template>
<script>
export default {
    data(){
        return{
            data:[],
        }
    },
    methods:{
        alldat: function () {
        
            this.$http.get('/letter')
            .then((res)=>{
                this.data = res.data
            })
        }
    },
    beforeMount(){
        this.alldat();
    }
}
</script>